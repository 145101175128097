import React, { Component, useEffect } from "react"
import { Link } from "gatsby"
import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Heading from "../common/heading/Heading"

export type Project = {
  node: {
    id?: string
    slug?: string
    titulo?: string
    imagenPrincipal: IGatsbyImageData
  }
}

type ProjectsProps = {
  projects: Project[]
  noGutters?: boolean
}

const Projects: React.FC<ProjectsProps> = ({ projects, noGutters }) => {
  //   const apartments = _.slice(this.props.items, 0, 9)
  useEffect(() => {
    console.log("IN PROJECTS COMPONENTS WITH ARRAY:")
    console.log(projects)
  }, [])

  return (
    <div className="container">
      <Heading>Proyectos Actuales</Heading>
      <div className={`row${noGutters ? " no-gutters" : ""}`}>
        {projects.map(({ node }, index) => (
          <div className="col-lg-4 col-sm-6 h-100 mb-4" key={index}>
            <div className="property overflow-hidden">
              <div className="property-wrap projects">
                <div className="post-thumbnail">
                  <Link to={`apartment/}`}>
                    <GatsbyImage
                      className="img-apartment"
                      image={getImage(node.imagenPrincipal)}
                      alt={"proyecto imagen"}
                      imgStyle={{ objectFit: "cover" }}
                    />
                  </Link>
                </div>
                <div className="entry-content text-center text-uppercase item">
                  {/* <div className="property-title text-white">
                    <Link to={`apartment/${node.slug}`}>
                      {node.nombre}
                    </Link>
                  </div>
                  <div className="link-more">
                    <Link to={`apartment/${node.slug}`}>Explorar</Link>
                  </div>
                  <div className="line"></div> */}
                  <div className="con">
                    <h6 className="text-white">Explorar</h6>
                    <h5>
                      <Link
                        to={`proyecto/${node.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        {node.titulo}
                      </Link>
                    </h5>
                    <div className="d-none d-sm-block">
                      <div className="line"></div>
                      <Link to={`proyecto/${node.slug}`}>
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          size="2x"
                          style={{ color: "white", paddingTop: "4px" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Projects
