import {
  faExternalLinkAlt,
  faMapMarker,
  faInfoCircle,
  faMarker,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
// import ReactPlayer from 'react-player';
// import './slider4.scss';

// import slide1 from '../../../assets/images/slider/slide1.mp4'
// import slide1poster from '../../../assets/images/slider/slide1poster.jpg'
// import slide2 from '../../../assets/images/slider/slide2.jpg'
// import zonas from '../../../assets/images/slider/zonas.mp4'
// import zonasSnap from '../../../assets/images/slider/zonas-snap.jpg'
export type ProjectInfo = {
  node: {
    slug: string
    titulo: string
    calle?: string
    distrito?: string
    imagenFondo?: IGatsbyImageData
    logo?: string
    estatus?: string
    link?: string
    videoLink?: string
  }
}

type SliderProps = {
  // sliderData: Array<{ node: { fotoLink?: string | undefined, descripcion?: string, titulo?: string, videoLink?: string } }>
  projects: ProjectInfo[]
  onPlayVideo: (title: string, videoLink: string) => void
}

const HeroSlider: React.FC<SliderProps> = ({ projects, onPlayVideo }) => {
  const settings = {
    dots: true,
    // arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    // beforeChange: beforeChangeHandler
  }

  let height = { height: "100vh" }
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser())
    height = window.innerWidth >= 576 ? { height: "100vh" } : { height: "77vh" }

  console.log("********** slides:")
  console.log(projects)

  return (
    <div className={"slider-wrap position-relative"}>
      <Slider {...settings}>
        {projects.map(({ node }, index) => (
          <div>
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{ ...height }}
            >
              <GatsbyImage
                className="w-100 h-100 position-absolute"
                objectFit="cover"
                image={getImage(node.imagenFondo)}
                alt=""
              />
              <div
                className="w-100 h-100 position-absolute"
                style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
              ></div>
              <div
                className="slider-new-content d-flex flex-column p-5 p-md-2"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              >
                {/* <div style={{width: '600px', height: '500px', border: '2px solid yellow'}}></div> */}
                <div className="d-flex">
                  <div
                    className="d-inline-block"
                    style={{ flexDirection: "column" }}
                  >
                    {/* <div className='rounded-3 bg-success text-align-center py-1 px-2 slide-right mb-4'> */}
                    {/* <div style={{ height: '50px', display: 'flex' }}>
                                        <GatsbyImage image={getImage(logos[index])} alt="project logo" objectFit='contain' objectPosition={'left top'} style={{ height: '100%' }} />
                                    </div> */}
                    <h6 className="d-inline-block p-1 bg-success text-align-center slide-right text-white">
                      {node.estatus}
                    </h6>
                    {/* </div> */}

                    <div className="d-flex slide-project-title slide-right">
                      <div className="caption">
                        <h1 className="text-white">{node.titulo}</h1>
                      </div>
                    </div>

                    {/* <h3 className="text-white slide-left mt-4">{slide.eslogan}</h3> */}
                    <div className="slide-right d-flex flex-column flex-md-row align-items-md-center mb-3">
                      <div className="slider-title-container p-0 pe-md-3">
                        <h3
                          className="text-white text-uppercase m-0"
                          style={{ letterSpacing: "2px" }}
                        >
                          {node.distrito}
                        </h3>
                      </div>
                      <div className="p-0 ps-md-3 text-white">
                        {/* <p style={{ color: "#fff", letterSpacing: "1px" }}> */}
                          {node.calle}
                        {/* </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-1 slide-right">
                  <a
                    onClick={() => window.open(`${node.link}`)}
                    type="button"
                    className="btn btn-success text-white rounded-3 p-1 me-4"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      size="1x"
                      className="me-2"
                    />
                    Web
                  </a>
                  <Link
                    to={`proyecto/${node.slug}`}
                    type="button"
                    className="btn btn-success text-white rounded-3 p-1 me-4"
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="1x"
                      className="me-2"
                    />
                    Info
                  </Link>
                  <button
                    type="button"
                    className="btn btn-success text-white rounded-3 p-1 me-4"
                    onClick={() => onPlayVideo(node.titulo, node.videoLink)}
                  >
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      size="1x"
                      className="me-2"
                    />
                    Video
                  </button>
                </div>
              </div>
              {/*<div className="w-100 h-100 position-absolute" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}></div>
                            <div className="h-100">
                                <div className="slider-content d-flex flex-column justify-content-center align-items-start h-100 px-4 ms-5">
                                    <div className='rounded-3 bg-success text-align-center py-1 px-2 slide-right mb-4'>
                                        <h6 className='text-white m-0'>{slide.status}</h6>
                                    </div>
                                    <div className='overflow-hidden'>
                                        <div className="my-1 d-flex slide-project-title slide-left">
                                            <div className='me-1 me-md-3'>
                                                <h3 className="text-white" style={{ letterSpacing: '2px', fontWeight: '600' }}>Proyecto:</h3>
                                                <h6 className="text-success" style={{ letterSpacing: '2px' }}>{slide.distrito}</h6>
                                            </div>

                                        </div>
                                        <div my-4 style={{ maxHeight: '90px', maxWidth: '300px' }}>
                                            <GatsbyImage image={getImage(logos[index])} alt="project logo" />
                                        </div>
                                        <h3 className="text-white slide-left mt-4">{slide.eslogan}</h3>
                                        <p className="slide-left mb-5" style={{ color: '#fff', letterSpacing: '1px' }}>
                                            <span className='me-2'><FontAwesomeIcon icon={faMapMarker} size="2x" color='#fff' style={{ marginBottom: '-3px' }} /></span>
                                            {slide.calle}
                                        </p>
                                        <div className='mt-4 slide-right'>
                                            <button type="button" className="btn btn-danger rounded-3 p-2 me-4" >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" className='me-2' />
                                                Ir a Proyecto
                                            </button>
                                            <button type="button" className="btn p-0" >
                                                <FontAwesomeIcon icon={faPlayCircle} size="3x" color='#dc3545' />
                                            </button>
                                            <button type="button" className="btn p-2 ps-0" style={{ color: '#fff' }} >
                                                Ver Video
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        ))}

        {/* <div>
                    <div className='position-relative' style={height}>
                        <StaticImage className="w-100 h-100 position-absolute" placeholder='blurred' objectFit='cover' src="https://images.pexels.com/photos/267501/pexels-photo-267501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" />
                        <div className="w-100 h-100 position-absolute" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}></div>
                        <div className="h-100">
                            <div className="slider-content d-flex flex-column justify-content-center align-items-start h-100 px-4 ms-5">
                                <div className='rounded-3 bg-success text-align-center py-1 px-2 slide-right mb-4'>
                                    <h6 className='text-white m-0'>En Construccion</h6>
                                </div>
                                <div className='overflow-hidden'>
                                    <div className="my-1 d-flex slide-project-title slide-left">
                                        <div className='me-1 me-md-3'>
                                            <h3 className="text-white" style={{ letterSpacing: '2px', fontWeight: '600' }}>Proyecto: TESSSSSTTT</h3>
                                            <h6 className="text-success" style={{ letterSpacing: '2px' }}>Jesus Maria</h6>
                                        </div>

                                    </div>
                                    <div className="my-4 slide-left">
                                        <StaticImage className="my-4 slide-left" src="https://eugenio-garzon.com/assets/images/logo.png" alt="A kitten" height={90} />
                                    </div>
                                    <h3 className="text-white slide-left mt-4">{'A little bit of a long description here for the cause.'} </h3>
                                    <p className="slide-left mb-5" style={{ color: '#fff', letterSpacing: '1px' }}>
                                        <span className='me-2'><FontAwesomeIcon icon={faMapMarker} size="2x" color='#fff' style={{ marginBottom: '-3px' }} /></span>
                                        Av. General Eugenio Garzón 1535 - 1545 -1547 -1549
                                    </p>
                                    <div className='mt-4 slide-right'>
                                        <button type="button" className="btn btn-danger rounded-3 p-2 me-4" >
                                            <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" className='me-2' />
                                            Ir a Proyecto
                                        </button>
                                        <button type="button" className="btn p-0" >
                                            <FontAwesomeIcon icon={faPlayCircle} size="3x" color='#dc3545' />
                                        </button>
                                        <button type="button" className="btn p-2 ps-0" style={{ color: '#fff' }} >
                                            Ver Video
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        {/* 
                <div>
                    <div style={{height: '100vh'}}>
                        <div className="slider-image" style={{ position: 'absolute', width: '100%', height: '100%', backgroundImage: `url(https://images.pexels.com/photos/267501/pexels-photo-267501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)`, backgroundPosition: 'center' }}>
                            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.4)' }}></div>
                        </div>

                        <div className="container">
                            <div className="slider-content justify-content-center" style={{ alignItems: 'flex-start' }}>
                                <div className="text-center w-100">
                                    <h2 className="text-primary">{'Some Title Here'}</h2>
                                    <h3 className="text-primary d-inline">{'A little bit of a long description here for the cause.'} </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </Slider>
    </div>
  )
}

export default HeroSlider

{
  /* <div className="slider-item position-relative" >
{node.foto &&
    <GatsbyImage image={getImage(node.foto)} alt={'image'} style={{ maxHeight: '60vh' }} />
}
<div className="slide-content-container">
    <div style={{clear: 'both'}}>
        <h2 className="text-secondary">{node.titulo}</h2>
    </div>
    <div style={{clear: 'both'}}>
        <h5>{node.descripcion}</h5>
    </div>
</div>
</div> */
}
