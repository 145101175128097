import React, { Component } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';
config.autoAddCss = true

// import './heading.scss';

type HeadingProps = {
    subTitle?: string
    showLine?: 'show' | 'hide'
    className?: string
}

class Heading extends Component<HeadingProps> {
    render() {
        const { subTitle, showLine } = this.props;
        return (
            <div className={`${this.props.className ? this.props.className + ' ' : ''}heading-title`}>
                {showLine !== 'hide' ?
                    <div className="heading-decor">
                        <FontAwesomeIcon icon={faGripHorizontal} size='2x' className="text-primary" style={{ height: '18px', marginTop: '2px' }} />
                        {/* <StaticImage src="" alt="logo facebook" width={18} /> */}
                    </div>
                    : ""
                }
                <h3 className="text-uppercase" style={showLine !== 'hide' ? { marginLeft: '20px' } : {}}>{this.props.children}</h3>
                {subTitle ?
                    <h4 className={`sub-title`}>{subTitle}</h4>
                    : ""
                }

            </div>
        )
    }
}

export default Heading