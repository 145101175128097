import {
  faCommentDollar,
  faHome,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Heading from "../common/heading/Heading"

type OurServicesProps = {
  title?: string
}

export const OurServices: React.FC<OurServicesProps> = ({ title }) => {
  return (
    <div className="ourservices" style={{ position: "relative" }}>
      <div className={"services-overlay"}></div>
      <div className="position-relative">
        <div className="container">
          {title && <Heading>{title}</Heading>}
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon justify-content-center">
                      <FontAwesomeIcon
                        icon={faHome}
                        size="4x"
                        className="text-primary"
                      />
                    </div>
                    <h4 className="featured-box-title text-uppercase">
                      Compra / Venta
                    </h4>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Comercializamos productos inmobiliarios propios y de
                      terceros (casas, departamentos, terrenos, locales
                      comerciales, oficinas, entre otros). Brindando la ventaja
                      que las propiedades que se expongan han sido revisadas por
                      agentes de la empresa. Ayudando a la obtención del precio
                      real del inmueble acorde al mercado donde este se ubique.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <FontAwesomeIcon
                        icon={faPencilRuler}
                        size="4x"
                        className="text-primary"
                      />
                    </div>
                    <h4 className="featured-box-title text-uppercase">
                      CONSTRUCCIÓN Y REMODELACIÓN
                    </h4>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Damos el servicio de construcción, remodelación y/o
                      servicios generales que tenga como referencia la
                      construcción, tanto a personas jurídicas y naturales;
                      contamos con la experiencia, capacidad técnica y
                      profesional para desarrollar todo tipo de proyecto.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <FontAwesomeIcon
                        icon={faCommentDollar}
                        size="4x"
                        className="text-primary"
                      />
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      ASESORAMIENTO
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Asesoramos en la gestión, construcción y ejecución del
                      proyecto inmobiliario (cubrimos todas las etapas del
                      proceso desde el diseño, construcción, acabados y todo lo
                      que el cliente requiera hasta la entrega). También
                      brindamos la asesoría en el sector comercial, legal,
                      técnico y financiero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
