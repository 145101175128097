import React from "react"
import { Parallax } from "react-parallax"

const Info = () => {
  return (
    <div className="container mt-5 pt-5 mb-5">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="box-counter text-center text-primary">
            <div className="box-counter-wrap">
              <div className="counter-nummer">1 MILLION +</div>
              <div className="counter-text">DE METROS CUADRADOS CONSTRUIODS</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="box-counter text-center text-primary">
            <div className="box-counter-wrap">
              <div className="counter-nummer">400 +</div>
              <div className="counter-text">DE OBRAS COMPLETADAS</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="box-counter text-center text-primary">
            <div className="box-counter-wrap">
              <div className="counter-nummer">500k +</div>
              <div className="counter-text">
                DE DPTOS VENDIDOS
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
