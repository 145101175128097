import * as React from "react"
import { graphql, Link, StaticQuery, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

import Helmet from "react-helmet"
import OverviewSection from "../components/overview/Overview"
import Layout from "../components/layout/Layout"
import HeroSlider from "../components/slider/HeroSlider"
import CounterBlock from "../components/countup/Countup"
import Videos from "../components/videos/Videos"
import Apartments from "../components/apartments/Apartments"
import Gallery from "../components/gallery/Gallery"
import Googlemap from "../components/map/Googlemap"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { useEffect, useState } from "react"
import Projects from "../components/projects/Projects"
import Info from "../components/info/Info"
import { Button, Modal } from "react-bootstrap"
import { OurServices } from "../components/services/OurServices"

const IndexPage = props => {
  const [showVideo, setShowVideo] = useState(false)
  const [videoLink, setVideoLink] = useState("")
  const [videoTitle, setVideoTitle] = useState("")

  // const slidesInfo = props.data.allContentfulMahpsa.edges[0].node
  //   .slidersInfo as SlideInfo[]
  const slidesBackgrounds = props.data.allContentfulMahpsa.edges[0].node
    .slidersFondos as any[]
  // const slidesLogos = props.data.allContentfulMahpsa.edges[0].node
  //   .slidersLogos as any[]

  const projects = props.data.allContentfulMahpsaProyecto.edges
  console.log("All contenful mahpsa projects:")
  console.log(projects)

  const apartments = props.data.allContentfulDepartamento.edges as object[]

  const pagePath = props.location.pathname
  const [negTopMargin, setNegTopMargin] = useState("0px")
  //   useEffect(() => {
  //     const navbar = window.document.getElementById("masthead");
  //     const navbarHeight = navbar.offsetHeight;
  //     console.log('The height of the navbar is: ' + navbarHeight);
  //     // setNavHeight(navbarHeight * -1)
  // }, [])

  console.log("Background images: ")
  console.log(slidesBackgrounds)

  const navHeightHandler = (height: number) => {
    console.log("FROM INDEX: THE HEIGHT RECEIVED IS: " + height)
    const negativeMargin = height * -1
    const negativeMarginPx = negativeMargin + "px"
    setNegTopMargin(negativeMarginPx)
  }

  const onPlayVideoHandler = (title: string, videoLink: string) => {
    setShowVideo(true)
    setVideoLink(videoLink)
    setVideoTitle(title)
  }

  console.log("The negative margin is: " + negTopMargin)
  return (
    <Layout pagePath={pagePath} getNavHeight={navHeightHandler}>
      <div>
        <Helmet>
          <title>Residencial Rosaura | Mahpsa</title>
        </Helmet>

        <div
          className="section-wrapper pt-0 pb-0"
          style={{ marginTop: negTopMargin }}
        >
          <HeroSlider projects={projects} onPlayVideo={onPlayVideoHandler} />
        </div>
        <div className="section-wrapper text-center pt-3 pt-md-5 mt-3 mt-md-5">
          <h2 className="text-primary text-uppercase fw-bolder">Bienvenidos</h2>
          <h3 className="text-uppercase mt-3">
            TENEMOS MÁS DE 25 AÑOS DE EXPERIENCIA
          </h3>
          <Info />
        </div>
        <div className="section-wrapper py-5 my-5">
          <Projects projects={projects} noGutters={false} />
        </div>
        <div className="section-wrapper p-0">
          <OurServices title="Nuestros Servicios" />
        </div>


        <Modal
          show={showVideo}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => setShowVideo(false)}>
            <Modal.Title id="contained-modal-title-vcenter">
              {videoTitle || "No hay video"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {videoLink && (
              <iframe
                width="100%"
                height="300px"
                src={`${videoLink}&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=0&frameborder=1`}
                frameBorder="1"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowVideo(false)}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulMahpsa {
      edges {
        node {
          slidersFondos {
            gatsbyImageData(placeholder: BLURRED)
          }
          slidersLogos {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
          slidersInfo {
            nombre
            calle
            distrito
            eslogan
            status
            video
          }
        }
      }
    }
    allContentfulMahpsaProyecto {
      edges {
        node {
          calle
          distrito
          estatus
          link
          slug
          titulo
          imagenFondo {
            gatsbyImageData(placeholder: BLURRED)
          }
          imagenPrincipal {
            gatsbyImageData(placeholder: BLURRED)
          }
          videoLink
        }
      }
    }
    numeros: allContentfulProyectoNumerosProyectoJsonNode {
      edges {
        node {
          numero
          titulo
        }
      }
    }
    allContentfulDepartamento {
      edges {
        node {
          descripcion {
            raw
          }
          areaTotal
          vendido
          plano {
            title
            gatsbyImageData(placeholder: BLURRED, quality: 80, formats: WEBP)
          }
        }
      }
    }
    allContentfulSlider {
      edges {
        node {
          fotoLink
          descripcion
          titulo
          videoLink
        }
      }
    }
  }
`
